import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Input, Menu, Dropdown } from "antd";

export default function AddressAutocompleteInput(props: {
  value: string;
  onChange: (value: string) => void;
  onSelect: (address: string, placeID: string) => void;
  onSuggestionClick: (suggestion: any) => void;
}) {
  const { value, onChange, onSelect, onSuggestionClick } = props;

  return (
    <PlacesAutocomplete value={value} onChange={onChange} onSelect={onSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <Dropdown
            overlay={
              <Menu>
                {suggestions.map((suggestion) => {
                  return (
                    <Menu.Item
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.description}
                    >
                      <div
                        style={{ width: "100%", height: "100%" }}
                        onClick={() => onSuggestionClick(suggestion)}
                      >
                        {suggestion.description}
                      </div>
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
            visible={!!value && !!suggestions.length}
          >
            <Input {...getInputProps()} />
          </Dropdown>
        </>
      )}
    </PlacesAutocomplete>
  );
}

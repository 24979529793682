import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { LoginScreen } from "./features/auth";
import { AdminDashboard } from "./features";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Actions, rootReducer } from "./features/Reducers";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router";
import { LoginData, User } from "./features/auth/models";
import { authSlice } from "./features/auth/slice";
import { ResetPasswordPage } from "./features/resetPassword/ResetPasswordPage";
const reducers = combineReducers(rootReducer);
export type RootState = ReturnType<typeof reducers>;

export const store = configureStore({ reducer: rootReducer });

const MainSwitch = () => {
  const authenticated = useSelector<RootState>((state) => state.auth.user);
  return (
    <>
      {!authenticated && <Redirect to={"/login"} />}
      <Switch>
        <Route exact path="/" component={AdminDashboard} />
        <Route path={"/dashboard"} component={AdminDashboard} />
        <Route exact path={"/login"} component={LoginScreen} />
      </Switch>
    </>
  );
};

const RootApp = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loginJson = localStorage.getItem("loginData");
    if (loginJson == null) {
      setLoading(false);
      return;
    }
    const userJson = localStorage.getItem("user");
    if (userJson == null) {
      return;
    }
    const loginData = JSON.parse(loginJson) as LoginData;
    const user = JSON.parse(userJson) as User;
    dispatch(Actions.login.setLoginCreds(loginData));

    if (loginData.jwt) {
      dispatch(
        authSlice.actions.autoLoggedInUser({ user: user, authData: loginData })
      );
      if (user.role === "Administrator") {
        dispatch({
          type: "currentRetailer/setRetailerSelectable",
          payload: true,
        });
      } else {
        dispatch({
          type: "currentRetailer/setRetailerSelectable",
          payload: false,
        });
        dispatch({
          type: "currentRetailer/setCurrentRetailer",
          payload: user.retailer!,
        });
      }

      setLoading(false);
    }
  }, [dispatch]);

  return !loading ? (
    <Router>
      <div style={{ width: "100vw", height: "100vh" }}>
        <Route path="/" component={MainSwitch} />
        <Route path="/resetPassword" component={ResetPasswordPage} />
      </div>
    </Router>
  ) : null;
};

export const App = () => {
  return (
    <Provider store={store}>
      <RootApp />
    </Provider>
  );
};

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Divider,
  Alert,
  Select,
  Button,
  Radio,
  DatePicker,
  notification,
} from "antd";
import moment, { Moment } from "moment";
import AddRetailerModal from "../retailers/AddRetailerModal";
import { useDispatch, useSelector } from "react-redux";
import { editConnection, getConnections } from "./api";
import { getRetailers } from "../retailers/api";
import { RootState } from "../../App";
import { Col, Row } from "antd";
import { clearConnectionStatus, hideEditConnectionModal } from "./slice";
import { RadioChangeEvent } from "antd/lib/radio";
import { DatePickerProps } from "antd/lib/date-picker";
import { TimePicker } from "antd";
import radioUnchecked from "../../resources/radio-unchecked.png";
import radioChecked from "../../resources/radio-checked.png";
import { showAddRetailerModal } from "../retailers/slice";
import { clearProperties, showAddPropertyModal } from "../properties/slice";
import { resetRetailerService, showAddServiceModal } from "../services/slice";
import { clearSubservices, showAddSubserviceModal } from "../subservices/slice";
import AddPropertyModal from "../properties/AddPropertyModal";
import AddSubserviceModal from "../subservices/AddSubserviceModal";
import AddServiceModal from "../services/AddServiceModal";
import { getServices, getServiceByRetailerId } from "../services/api";
import { getSubservices } from "../subservices/api";
import { getProperties, getPropertiesBySubServiceId } from "../properties/api";
import { getSubservicesByServiceId } from "../subservices/api";
import { Subservice } from "../subservices/models";
import { Service } from "../services/models";
import { Properties } from "../properties/models";
import { BASE_URL } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";

const { Option } = Select;

export default function EditModal(props: {
  row: {
    id: string;
    name: string;
    description: string;
    retailerID: string;
    serviceID: string;
    subserviceID: string;
    propertyID: string;
    spreadsheetURL: string;
    selectedDateRange: string;
    lastCount: string;
    lastCountType: string;
    fromDate: string;
    toDate: string;
    beginningCount: string;
    beginningType: string;
    beginningDate: string;
    endingCount: string;
    endingType: string;
    endingDate: string;
    selectedInterval: string;
    everyTimeCount: string;
    everyTimeType: string;
    everyWeek: string;
    everyWeekAtTime: string;
    everyDayCount: string;
    everyDayDayType: string;
    everyDayMonthOrYearType: string;
    appendOrReplace: string;
    pageName: string;
    pageTitle: string;
    connectuserId: number;
    created_at: string;
    updated_at: string;
    connectionAndIntervalType: string;
  };
}) {
  [];
  const [form] = Form.useForm();
  const { row } = props;
  const [selectedTime, setSelectedTime] = useState<Moment | null>(
    moment(new Date())
  );

  const [spreadsheetURL, setSpreadsheetURL] = useState("");
  const [oldData, setSetOldData] = useState({
    retailerID: row.retailerID,
    subserviceID: row.subserviceID,
    serviceID: row.serviceID,
    propertyID: row.propertyID,
  });

  const [fromDate, setFromDate] = useState<Moment | null>(moment(row.fromDate));
  const [toDate, setToDate] = useState<Moment | null>(moment(row.toDate));

  const [rangeUncheckedImg, setRangeUncheckedImg] = useState<number>(
    Number(row.selectedDateRange)
  );
  const [intervalUncheckedImg, setIntervalUncheckedImg] = useState<number>(
    0
    // Number(row.selectedInterval)
  );
  const [everyWeek, setEveryWeek] = useState(row.everyWeek);
  const [everyWeekAtTime, setEveryWeekAtTime] = useState<Moment | null>(
    moment(new Date())
  );
  const [everyDayCount, setEveryDayCount] = useState(1);
  const [everyDayDayType, setEveryDayDayType] = useState("day");
  const [everyDayMonthOrYearType, setEveryMonthOrYearType] = useState("month");
  const [everyTimeType, setEveryTimeType] = useState(
    row.everyTimeType !== "string" ? row.everyTimeType : "hours"
  );
  const [everyTimeCount, setEveryTimeCount] = useState("1");
  const [dateRangeValue, setDateRangeValue] = React.useState(1);
  const [lastCount, setLastCount] = React.useState("1");
  const [lastCountType, setLastCountType] = React.useState("days");
  const [selectedDateRange, setSelectedDateRange] = React.useState("1");
  const [selectedInterval, setSelectedInterval] = useState(1);
  const [abbreviation, setAbbreviation] = useState(
    Number(row.everyDayCount) == 1
      ? "st"
      : Number(row.everyDayCount) == 2
        ? "nd"
        : Number(row.everyDayCount) == 3
          ? "rd"
          : "th"
  );
  const [validStatus, setValidStatus] = useState({
    name: true,
    retailerID: true,
    sheetUrl: true,
    pageName: true,
  });

  const visible = useSelector(
    (state: RootState) => state.connections.showEditModal === row.id
  );

  let connectionNameArray = row.name.split("-");
  // const [connectionName, setConnectionName] = useState<{ retailerPart?: string; servicePart?: string; subServicePart?: string; propertyPart?: string }>()
  const [connectionName, setConnectionName] = useState({
    retailerPart: connectionNameArray[0],
    propertyPart:
      connectionNameArray[1] !== undefined ? connectionNameArray[1] : "",
    servicePart:
      connectionNameArray[2] !== undefined ? connectionNameArray[2] : "",
    subServicePart:
      connectionNameArray[3] !== undefined ? connectionNameArray[3] : "",
  });
  const [connName, setConnName] = useState("");
  const retailers = useSelector((state: RootState) => state.retailers.all);
  const properties = useSelector(
    (state: RootState) => state?.properties?.propertyBySubService
  );

  const [isRunNow, setIsRunNow] = useState<boolean>(true);

  const [appendOrReplace, setAppendOrReplace] = React.useState("append");
  const [pageTitle, setPageTitle] = React.useState("Page");
  const [
    connectionAndIntervalType,
    setConnectionAndIntervalType,
  ] = React.useState(row?.connectionAndIntervalType || 'RunOnce');
  const [retailerID, setRetailerID] = React.useState<string>(row.retailerID);
  const [serviceID, setServiceID] = React.useState<string | null>(
    row.serviceID
  );
  const [subserviceID, setSubserviceID] = React.useState<string | null>(
    row.subserviceID
  );
  const [propertyID, setPropertyID] = React.useState<string | null>(
    row.propertyID
  );
  console.log("data propertyID", propertyID);

  const [connectuserId, setConnectUser] = React.useState<number | null>(
    row.connectuserId
  );
  console.log("data row", row);

  const [facebookDetails, setFacebookDetails] = useState<{
    id: string;
    userName: string;
    photo: string;
    accessToken: string;
    name: string;
    userId: string;
    expairyDate: number;
    createdDate: Date;
    updatedDate: Date;
  }>();

  useEffect(() => {
    if (!facebookDetails) {
      (async () => {
        const result = await fetch(
          `${BASE_URL}api/GetAllSetting`,
          createAuthConfig()
        );
        const data = await result.json();
        setFacebookDetails(data.settingDetails);
      })();
    }
  }, [facebookDetails]);

  const { editError, editSuccess } = useSelector(
    (state: RootState) => state.connections
  );

  const dispatch = useDispatch();

  const [subserviceOptions, setSubserviceOptions] = useState<Subservice[]>([]);
  const services = useSelector((state: RootState) => state.services.all);
  const [retailerServices, setRetailerServices] = useState<Service[]>([]);
  const [subserviceArray, setSubserviceArray] = useState<string[]>([]);
  const [propertyOptions, setPropertyOptions] = useState<Properties[]>([]);
  const [facebookPages, setFacebookPages] = useState<string[]>([]);

  //Get login user detail
  const loggedInUser = useSelector((state: RootState) => state.auth.user);
  // {console.log(loggedInUser,"User Auth")}

  const iframe = document.getElementsByTagName("iframe");
  if (iframe && iframe[0]) {
    iframe[0].remove();
  }

  useEffect(() => {
    if (visible) {
      (async () => {
        const result = await fetch(
          `${BASE_URL}api/GetFacebbokPageList`,
          createAuthConfig()
        );
        console.log("result", result);

        const { connection_data } = await result.json();
        console.log("connection_data", await connection_data);

        const pagesName = connection_data.data.map((item: any) => item.name);
        const sortedPages = pagesName.sort((a: string, b: string) => {
          let fa = a.toLowerCase(),
            fb = b.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        setFacebookPages(sortedPages);
      })();
    }
  }, [visible]);

  useEffect(() => {
    // setPropertyOptions(properties);
  }, [properties]);

  useEffect(() => {
    let connectionText = "";
    if (connectionName?.retailerPart !== "")
      connectionText += connectionName?.retailerPart;
    if (connectionName?.propertyPart !== "" && propertyID)
      connectionText += `-${connectionName?.propertyPart}`;
    if (connectionName?.servicePart !== "")
      connectionText += `-${connectionName?.servicePart}`;
    if (connectionName?.subServicePart !== "")
      connectionText += `-${connectionName?.subServicePart}`;

    setConnName(connectionText);
  }, [connectionName]);

  useEffect(() => {
    setRangeUncheckedImg(Number(row.selectedDateRange));
    setSpreadsheetURL(row.spreadsheetURL);
    setFromDate(moment(row.fromDate));
    setToDate(moment(row.toDate));
  }, [row]);

  useEffect(() => {
    if (editSuccess) {
      notification.success({
        message: "Success Message",
        description: `${row.name} connection updated successfully.`,
      });
      dispatch(clearConnectionStatus());
      dispatch(resetRetailerService());
      dispatch(clearSubservices());
      dispatch(clearProperties());
    }
    if (editError) {
      notification.error({
        message: "Error Message",
        description: `Unable to update ${row.name} connection.`,
      });
      dispatch(clearConnectionStatus());
    }
  }, [editError, editSuccess]);

  const { editConnectionLoading: editLoading } = useSelector(
    (state: RootState) => state.connections
  );

  const error = useSelector(
    (state: RootState) => state.connections.addConnectionError
  );

  //handle Submit form
  const submitForm = (values: any, isRunNow: boolean) => {
    isRunNow
      ? dispatch(editConnection(values))
      : dispatch(editConnection(values));
  };

   const onChanget = (value: Moment) => {
      console.log("everyWeekAtTime value", value.toString());
  
      setSelectedTime(value);
      setEveryWeekAtTime(value);
      form.setFieldsValue({
        selectedInterval: "2",
        everyTimeCount: everyTimeCount.toString(),
        everyTimeType: everyTimeType,
        everyWeek: everyWeek,
        everyWeekAtTime: value?.toString(),
        everyDayCount: everyDayCount.toString(),
        everyDayDayType: everyDayDayType,
        everyDayMonthOrYearType: everyDayMonthOrYearType,
      });
    };

  // handle modal ok
  const handleModalOk = (isRunNow: any) => () => {
    // setIsRunNow(isRunNow)
    //validates values then sends them to submitForm()
    if (isRunNow === null) {
      return false;
    }

    form
      .validateFields()
      .then((values) => {
        //Check here for run now and schedule now
        const copy = { ...values };
        copy.connectionAndIntervalType = connectionAndIntervalType;
        // date range
        // 1
        copy.lastCount = 0;
        copy.lastCountType = "string";
        // 2
        copy.fromDate = "string";
        copy.toDate = "string";
        // 3
        copy.beginningCount = 0;
        copy.beginningDate = "string";
        copy.beginningType = "string";
        // 4
        copy.endingType = "string";
        copy.endingDate = "string";
        copy.endingCount = 0;
        // add scheduler
        // 1
        copy.everyTimeCount = 0;
        copy.everyTimeType = "string";
        // 2
        copy.everyWeekAtTime = "string";
        copy.everyWeek = "string";
        // 3
        copy.everyDayCount = 0;
        copy.everyDayDayType = "string";
        copy.everyDayMonthOrYearType = "string";
        if (values.serviceID == undefined) {
          copy.serviceID = null;
        }
        if (values.subserviceID == undefined) {
          copy.subserviceID = null;
        }
        if (values.propertyID == undefined) {
          copy.propertyID = null;
        }
        switch (rangeUncheckedImg) {
          case 1:
            copy.lastCount = Number(values.lastCount);
            copy.lastCountType = values.lastCountType;
            break;
          case 2:
            let fromDate = moment(values.fromDate).format("MM/DD/YYYY");
            let toDate = moment(values.toDate).format("MM/DD/YYYY");
            copy.fromDate = fromDate;
            copy.toDate = toDate;
            break;
          case 3:
            copy.beginningCount = Number(values.beginningCount);
            copy.beginningDate = moment(values.beginningDate).format(
              "MM/DD/YYYY"
            );
            copy.beginningType = values.beginningType;
            break;
          case 4:
            copy.endingType = values.endingType;
            copy.endingDate = moment(values.endingDate).format("MM/DD/YYYY");
            copy.endingCount = Number(values.endingCount);
            break;

          default:
            break;
        }

        if (!isRunNow) {
          switch (intervalUncheckedImg) {
            case 1:
              copy.everyTimeCount = values.everyTimeCount.toString();
              copy.everyTimeType = values.everyTimeType;
              break;
            case 2:
              copy.everyWeekAtTime = moment(everyWeekAtTime?.toString()).format(
                "HH:mm"
              );
              copy.everyWeek = values.everyWeek;
              break;
            case 3:
              copy.everyDayCount = values.everyDayCount;
              copy.everyDayDayType = values.everyDayDayType;
              copy.everyDayMonthOrYearType = everyDayMonthOrYearType;
              break;

            default:
              break;
          }
        }
        console.log(copy, "copy");

        submitForm(copy, isRunNow);
      })
      .catch((e) => { });
  };
  // handle Cancel
  const handleCancel = () => {
    form.resetFields();
    setRetailerServices([]);
    setSubserviceOptions([]);
    setPropertyOptions([]);
    dispatch(hideEditConnectionModal());
  };
  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  // useEffect(() =>{

  // })
  useEffect(() => {
    (async () => {
      let data = retailers.filter((data) => data.name === retailerID);
      let id = data[0].id;
      console.log(data, "---------------data");
      await setServices(id, false);
    })();
  }, [services]);

  useEffect(() => {
    (async () => {
      // await setSubserviceData(Number(serviceID), false);
    })();
  }, [subserviceArray]);

  const setPropertyData = async (retailerId: string, clear: boolean = true) => {
    console.log("retailerId12", retailerId);

    if (clear) {
      setRetailerServices([]);
      setSubserviceOptions([]);
      form.setFieldsValue({ serviceID: "" });
      form.setFieldsValue({ subserviceID: "" });
    }
    const result = await fetch(
      `${BASE_URL}api/GetProperties/${retailerId}`,
      createAuthConfig()
    );
    let data = await result.json();
    console.log("data ffffffff", data);
    setPropertyOptions(data?.property);

  };

  // handle Change Retailer
  const handleChangeRetailer = async (value: string) => {
    console.log(value, "handleChangeRetailer");

    let data = retailers.filter((data) => data.name === value);
    console.log("retailers", data);

    let id = data[0].id;
    form.resetFields(["serviceID", "subserviceID", "propertyID"]);
    await setPropertyData(id);
    // await setPropertyData(value);

    // await setServices(id, true);

    dispatch(resetRetailerService());
    dispatch(clearSubservices());
    dispatch(clearProperties());
    setRetailerID(value);

    if (value == oldData.retailerID) {
      setServiceID(oldData.serviceID);
      setSubserviceID(oldData.subserviceID);
      setPropertyID(oldData.propertyID);
    } else {
      setServiceID(null);
      setSubserviceID(null);
      setPropertyID(null);
    }

    // setRetailerID(id);
    form.setFieldsValue({
      retailerID: value,
    });
    setValidStatus({ ...validStatus, retailerID: true, name: true });

    // setRetailerID(id);

    const noSpecialCharacters = value
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s/g, "");

    setConnectionName({
      ...connectionName,
      retailerPart: noSpecialCharacters,
      // servicePart: "",
      // subServicePart: "",
      // propertyPart: "",
    });
  };
  // useEffect(()=>setPropertyOptions(propertyOptions),[propertyOptions])
  // handle Change Service
  const handleChangeService = async (value: string) => {
    // setService(value);
    setServiceID(value);
    setSubserviceID(null);
    // setPropertyID(null);
    dispatch(clearSubservices());
    // dispatch(clearProperties())

    // let serviceId;
    let services = retailerServices.filter((data) => data.id == value);
    let id = services[0].uniqueID;

    await setSubserviceData(Number(value), false);
    form.setFieldsValue({ serviceID: value });
    // form.resetFields(["subserviceID", "propertyID"]);
    const noSpecialCharacters = id
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s/g, "");
    setPropertyOptions(propertyOptions);
    setConnectionName({
      ...connectionName,
      servicePart: noSpecialCharacters,
      // subServicePart: "",
      // propertyPart: "",
    });
  };

  // handle Change Subservice
  const handleChangeSubservice = (value: string) => {
    // debugger
    let subserviceId;
    setSubserviceID(value);
    // setPropertyID(null);

    // form.setFieldsValue({ propertyID: "" })
    // form.resetFields(["propertyID"]);

    form.setFieldsValue({ subserviceID: value });
    // dispatch(clearProperties())

    subserviceId = subserviceOptions.filter((data) => data.id == value);
    let subId = subserviceId[0].uniqueID;

    // dispatch(getPropertiesBySubServiceId({ subserviceID: Number(value) }));

    const noSpecialCharacters = subId
      ?.replace(/[^a-zA-Z0-9 ]/g, "")
      ?.replace(/\s/g, "");
    //setConnectionName((prev) => `${prev}-${noSpecialCharacters}`);

    setConnectionName({
      ...connectionName,
      subServicePart: noSpecialCharacters,
      // propertyPart: "",
    });
  };

  const setServices = async (retailerId: string, clear: boolean = true) => {
    if (clear) {
      setRetailerServices([]);
      setSubserviceOptions([]);
      setPropertyOptions([]);
      form.setFieldsValue({ serviceID: "" });
      form.setFieldsValue({ subserviceID: "" });
      form.setFieldsValue({ propertyID: "" });
    }
    const result = await fetch(
      `${BASE_URL}api/Retailers/${retailerId}`,
      createAuthConfig()
    );
    let data = await result.json();
    const serviceArray = data.service?.trim().split(",");
    const RetsubserviceArray = data.subservice?.trim().split(",");

    if (RetsubserviceArray) {
      setSubserviceArray(RetsubserviceArray);
    } else {
      setSubserviceArray([]);
    }

    if (serviceArray) {
      let serviceData = services.filter((item) =>
        serviceArray.includes(item.id.toString())
      );
      if (serviceData.length) {
        setRetailerServices(serviceData);
      }
    }
  };

  const setSubserviceData = async (serviceId: number, clear = true) => {
    if (clear) {
      form.setFieldsValue({ subserviceID: "", propertyID: "" });
      setSubserviceOptions([]);
      setPropertyOptions([]);
    }
    if (serviceId > 0) {
      const result = await fetch(
        `${BASE_URL}api/getsubservicedetail/${serviceId}`,
        createAuthConfig()
      );
      const data = await result.json();
      let subServices = data.subservice.filter((item: any) =>
        subserviceArray.includes(item.id.toString())
      );
      setSubserviceOptions(subServices);
      if (subServices.length == 0) {
        console.log("retailers", retailers);
        let retailer = retailers.find((data) => data.name === retailerID);
        // const result = await fetch(
        //   `${BASE_URL}api/GetPropertiesDetailserviceID/${serviceId}/${retailer?.id}`,
        //   createAuthConfig()
        // );
        const result = await fetch(
          `${BASE_URL}api/GetProperties/${retailer?.id}`,
          createAuthConfig()
        );
        const { data } = await result.json();
        console.log("data", data);

        setPropertyOptions(data.property);
      } else {
        if (Number(subserviceID))
          dispatch(
            getPropertiesBySubServiceId({ subserviceID: Number(subserviceID) })
          );
      }
    }
    form.setFieldsValue({ serviceID: serviceId });
  };

  // const onChanget = (value: Moment) => {
  //   setSelectedTime(value);

  //   setEveryWeekAtTime(value);
  //   form.setFieldsValue({
  //     selectedInterval: "2",
  //     everyTimeCount: everyTimeCount.toString(),
  //     everyTimeType: everyTimeType,
  //     everyWeek: everyWeek,
  //     everyWeekAtTime: value?.toString(),
  //     everyDayCount: everyDayCount.toString(),
  //     everyDayDayType: everyDayDayType,
  //     everyDayMonthOrYearType: everyDayMonthOrYearType,
  //   });
  // };

  // handle Change LastCount
  const handleChangeLastCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    setRangeUncheckedImg(1);
    if (dateRangeValue != 1) {
      setDateRangeValue(1);
    }
    form.setFieldsValue({
      lastCount: lastCount.toString(),
    });
    setLastCount(x);
    setSelectedDateRange(type.toString());
  };
  // handle Change LastCountType
  const handleChangeLastCountType = (e: string) => {
    form.setFieldsValue({
      lastCountType: e,
      selectedDateRange: "1",
    });
    setLastCountType(e);
    setSelectedDateRange("1");
  };
  // handle Change FromDate
  const handleChangeFromDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    form.setFieldsValue({
      fromDate: dateString,
    });
    setFromDate(date);
    setSelectedDateRange("2");
  };

  const handleChangeToDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    form.setFieldsValue({
      toDate: dateString,
    });
    setToDate(date);
    setSelectedDateRange("2");
  };
  // handle Change BeginningCount
  const handleChangeBeginningCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    form.setFieldsValue({
      selectedDateRange: "3",
      beginningCount: x.toString(),
      // beginningType: beginningType1,
      // beginningDate: beginning1Date,
    });
    // setBeginningCount(Number(x));
    setSelectedDateRange(type.toString());
  };
  // handle Change EndingCount
  const handleChangeEndingCount = (
    e: { target: { value: string } },
    type: number
  ) => {
    let x = e.target.value;
    form.setFieldsValue({
      selectedDateRange: "4",
      endingCount: x.toString(),
      // endingType: endingType,
      // endingDate: endingDate,
    });
    // setEndingCount(Number(x));
    setSelectedDateRange(type.toString());
  };
  // handle Change EveryTimeCount
  // onChange for pageTitle
  const onChangePagetitle = (e: RadioChangeEvent) => {
    setPageTitle(e.target.value);
    form.setFieldsValue({ pageTitle: e.target.value });
  };

  //onChange for the ConnectionAndIntervalType button
  const onChangeConnectionAndIntervalType = (e: RadioChangeEvent) => {
    setConnectionAndIntervalType(e.target.value);
    form.setFieldsValue({ connectionAndIntervalType: e.target.value });
  };
  // onChange Append Type
  const onChangeAppendType = (e: RadioChangeEvent) => {
    setAppendOrReplace(e.target.value);
    form.setFieldsValue({ appendOrReplace: e.target.value });
  };

  // handle Change SpreadsheetURL
  const handleChangeSpreadsheetURL = (event: { target: { value: string } }) => {
    setSpreadsheetURL(event.target.value);
    form.setFieldsValue({
      spreadsheetURL: event.target.value,
    });
  };

  const setServicesSubService = async (retailerId: string, clear: boolean = true) => {
    if (clear) {
      setRetailerServices([]);
      setSubserviceOptions([]);
      form.setFieldsValue({ serviceID: "" });
      form.setFieldsValue({ subserviceID: "" });
    }

    const retId = retailers.find(
      (item) => item.name === form?.getFieldValue("retailerID")
    );

    const result = await fetch(
      `${BASE_URL}api/GetPropertiesService?PropertyName=${retailerId}`,
      createAuthConfig()
    );
    let data = await result.json();
    console.log("value data", data?.property[0]);

    form.setFieldsValue({ serviceID: data?.property[0]?.serviceName })
    form.setFieldsValue({ subserviceID: data?.property[0]?.subserviceName })

    const serviceArray = data.service?.trim().split(",");
    const RetsubserviceArray = data.subservice?.trim().split(",");

    if (RetsubserviceArray) {
      setSubserviceArray(RetsubserviceArray);
    } else {
      setSubserviceArray([]);
    }

    if (serviceArray) {
      let serviceData = services.filter((item) =>
        serviceArray.includes(item.id.toString())
      );
      if (serviceData.length) {
        setRetailerServices(serviceData);
      }
    }
  };
  // handle Change Property
  const handleChangeProperty = async (value: string) => {
    let propertyDescription;
    propertyDescription = propertyOptions.filter((data) => data.name == value);
    let desc = propertyDescription[0]?.uniqueID;
    setPropertyID(value);
    // setProperty(value);
    await setServicesSubService(value)

    form.setFieldsValue({ propertyID: value });
    const noSpecialCharacters = desc
      ?.replace(/[^a-zA-Z0-9 ]/g, "")
      ?.replace(/\s/g, "");
    // setPropertyID
    // setConnectionName(connectionName.replace(propertyDesc, noSpecialCharacters))
    // setPropertyDesc(desc);
    // form.setFieldsValue({ name: connectionName.replace(propertyDesc, noSpecialCharacters) });
    setConnectionName({
      ...connectionName,
      propertyPart: noSpecialCharacters,
      // servicePart: "",
      // subServicePart: "",
    });
    // setConnectionName((prev) => `${prev}-${noSpecialCharacters}`);
    // form.setFieldsValue({ name: `${connectionName}-${noSpecialCharacters}` });
  };

  const format = "HH:mm";
  // handle Add Retailer
  const handleAddRetailer = () => {
    dispatch(showAddRetailerModal());
  };
  // handle Add Service
  const handleAddService = () => {
    dispatch(showAddServiceModal());
  };
  // handle Add Subservice
  const handleAddSubservice = () => {
    dispatch(showAddSubserviceModal());
  };
  // handle Add Property
  const handleAddProperty = () => {
    dispatch(showAddPropertyModal());
  };

  const beginningTypeOptions = [
    { value: "days", label: "Days", key: "Days" },
    { value: "weeks", label: "Weeks", key: "Weeks" },
    { value: "months", label: "Months", key: "Weeks" },
    { value: "years", label: "Years", key: "Weeks" },
  ];

  const handleBeginningTypeField = (e: string) => {
    // setBeginningType(e);
    form.setFieldsValue({
      selectedDateRange: "3",
      // beginningCount: beginningCount.toString(),
      beginningType: e,
      // beginningDate: beginning1Date?.toString(),
    });
  };

  useEffect(() => {
    dispatch(getConnections({ retailerId: "" }));
  }, [editLoading]);

  useEffect(() => {
    // if(!propertyOptions.length) {
    //   connectionName.propertyPart = ''
    // }
    if (!subserviceOptions.length) {
      connectionName.subServicePart = "";
    }
    if (!services.length) {
      connectionName.servicePart = "";
    }

    setConnectionName(connectionName);
  }, [services, subserviceOptions]);

  // useEffect(() => {
  //   let connectionText = "";
  //   if (connectionName?.retailerPart !== "")
  //     connectionText += connectionName?.retailerPart;
  //   if (connectionName?.servicePart !== "")
  //     connectionText += `-${connectionName?.servicePart}`;
  //   if (connectionName?.subServicePart !== "")
  //     connectionText += `-${connectionName?.subServicePart}`;
  //   if (connectionName?.propertyPart !== "")
  //     connectionText += `-${connectionName?.propertyPart}`;
  //   form.setFieldsValue({ name: connectionText });
  // }, [connectionName]);

  useEffect(() => {
    form.setFieldsValue({ name: connName });
  }, [connName]);

  return (
    <Modal
      maskClosable={false}
      title="Edit Connection"
      visible={visible}
      okText={"SCHEDULE NOW"}
      onOk={handleModalOk(null)}
      cancelText="Cancel"
      onCancel={handleCancel}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      width={1000}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}

      {row.updated_at == null || row.updated_at === "" ? (
        <span style={{ float: "right" }}>
          <b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")}{" "}
          {moment(row.created_at).format("hh:mma")} by {loggedInUser?.firstName}{" "}
          {loggedInUser?.lastName}
        </span>
      ) : (
        <>
          <span style={{ float: "right" }}>
            <b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")}{" "}
            {moment(row.created_at).format("hh:mma")} by{" "}
            {loggedInUser?.firstName} {loggedInUser?.lastName}
          </span>
          <br />
          <span style={{ float: "right" }}>
            <b>Last Edited: </b> {moment(row.updated_at).format("MM/DD/YYYY")}{" "}
            {moment(row.updated_at).format("hh:mma")} by{" "}
            {loggedInUser?.firstName} {loggedInUser?.lastName}
          </span>
        </>
      )}

      <Form
        {...layout}
        form={form}
        name="editConnection"
        className="form"
        initialValues={{
          id: row.id,
          name: row.name,
          description: row.description,
          retailerID: row.retailerID,
          serviceID: row.serviceID,
          subserviceID: row.subserviceID,
          propertyID: row.propertyID,
          spreadsheetURL: row.spreadsheetURL,
          selectedDateRange: row.selectedDateRange,
          lastCount: Number(row.lastCount) <= 0 ? 1 : Number(row.lastCount),
          lastCountType:
            row.lastCountType == "string" ? "days" : row.lastCountType,
          fromDate: moment(row.fromDate),
          toDate: moment(row.toDate),
          beginningCount:
            Number(row.beginningCount) <= 0 ? 1 : Number(row.beginningCount),
          beginningType:
            row.beginningType == "string" ? "days" : row.beginningType,
          beginningDate:
            row.beginningDate == "string"
              ? moment()
              : moment(row.beginningDate),
          endingCount:
            Number(row.endingCount) <= 0 ? 1 : Number(row.endingCount),
          endingType: row.endingType == "string" ? "days" : row.endingType,
          endingDate:
            row.endingDate == "string" ? moment() : moment(row.endingDate),
          selectedInterval: row.selectedInterval,
          everyTimeCount:
            Number(row.everyTimeCount) <= 0 ? 1 : row.everyTimeCount,
          everyTimeType:
            row.everyTimeType == "string" ? "minutes" : row.everyTimeType,
          everyWeek: row.everyWeek == "string" ? "monday" : row.everyWeek,
          everyWeekAtTime:
            row.everyWeekAtTime == "string"
              ? moment()
              : moment(row.everyWeekAtTime, "HH:mm"),
          everyDayCount: Number(row.everyDayCount) <= 0 ? 1 : row.everyDayCount,
          everyDayDayType: row.everyDayDayType,
          everyDayMonthOrYearType: row.everyDayMonthOrYearType,
          appendOrReplace: row.appendOrReplace,
          pageName: row.pageName,
          pageTitle: row.pageTitle,
          connectuserId: row.connectuserId,
          connectionAndIntervalType: row.connectionAndIntervalType,
        }}
      >
        <Form.Item name="id">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Connection name"
          name="name"
          rules={[{ required: true, message: "Connection name is required!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>

        {/* <Form.Item
          label="Facebook page name"
          name="pageName"
          rules={[{ required: true, message: "Page name is required!" }]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="Select a facebook page"
            onChange={(value) => setValidStatus({ ...validStatus, pageName: true })}
          >
            {facebookPages.map((pageName, index) => (
              <Select.Option key={index} value={pageName}>
                {pageName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
        {/* {console.log("subserviceOptions", subserviceOptions)} */}
        {/* {console.log(connectuser, 'connect-user')} */}
        <Form.Item
          label="Retailer"
          name="retailerID"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="Select a Retailer"
            onChange={handleChangeRetailer}
            defaultValue={row.retailerID}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return option?.value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
            }}
          >
            {retailers.map((option) => (
              <Select.Option key={option.id} value={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            size={"small"}
            style={{ marginLeft: "1em" }}
            onClick={handleAddRetailer}
          >
            Add Retailer
          </Button>
          <AddRetailerModal />
        </Form.Item>
        <Row style={{ justifyContent: "space-around" }}>
          <Col
            span={11}
            style={{
              border: "groove",
              borderRadius: "20px",
              borderWidth: "thin",
              padding: "15px",
            }}
          >
            <Form.Item label="Property " name="propertyID">
              {/* {console.log("data propertyID", propertyID)} */}
              <Select
                disabled={!propertyOptions.length}
                // disabled={!propertyOptions?.length || !propertyID}
                style={{ width: 250 }}
                value={
                  propertyID !== null && propertyOptions?.length >= 0
                    ? propertyID
                    : ""
                }
                aria-readonly={!propertyOptions?.length}
                onChange={handleChangeProperty}
                showSearch={true}
                filterOption={(inputValue, option) => {
                  return (
                    option?.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0 ?? false
                  );
                }}
              >
                {console.log("propertyID", propertyID)}
                {console.log(propertyOptions, "propertiesoptions")}
                {propertyOptions?.map((option) => (
                  <Select.Option key={option.id} value={option.name}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
              <Col span={6} offset={12} style={{ padding: "2px" }}>
                <Button
                  type="primary"
                  size={"small"}
                  style={{ marginLeft: "1em", marginTop: "1em" }}
                  onClick={handleAddProperty}
                >
                  Add Property
                </Button>
                <AddPropertyModal />
              </Col>
            </Form.Item>
          </Col>
          <Col
            span={11}
            style={{
              border: "groove",
              borderRadius: "20px",
              borderWidth: "thin",
              padding: "10px",
            }}
          >
            {console.log("value data ssssssssssssss", form.getFieldValue("serviceID"))}
            {console.log("value data ssssssssssssss subserviceID", form.getFieldValue("subserviceID"))}
            <Form.Item label="Service " name="serviceID">
              <Select
                style={{ width: 250 }}
                // disabled={!retailerServices.length || !propertyID}
                // disabled={!retailerServices.length || !serviceID}
                disabled={true}
              // onChange={handleChangeService}
              // value={form.getFieldValue("serviceID")}
              // value={serviceID ? serviceID : ""}
              // showSearch={true}
              // filterOption={(inputValue, option) => {
              //   return (
              //     option?.children
              //       .toLowerCase()
              //       .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              //   );
              // }}
              >
                {/* {retailerServices?.length > 0
                  ? retailerServices.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))
                  : null} */}
              </Select>
            </Form.Item>
            <Form.Item label="Subservice" name="subserviceID">
              <Select
                style={{ width: 250 }}
                disabled={true}
                // disabled={
                //   !subserviceOptions.length || !propertyID || !serviceID
                // }
                onChange={handleChangeSubservice}
                value={form.getFieldValue("subserviceID") || ""}
                // defaultValue={row.subserviceID}
                // value={
                //   subserviceID !== null && subserviceOptions.length > 0
                //     ? subserviceID
                //     : ""
                // }
                showSearch={true}
                filterOption={(inputValue, option) => {
                  return (
                    option?.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0 ?? false
                  );
                }}
              >
                {subserviceOptions.map((option) => (
                  <Select.Option key={option.id} value={option.id.toString()}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
              <Col style={{ padding: "18px" }}>
                <Button
                  type="primary"
                  size={"small"}
                  style={{ marginLeft: "1em" }}
                  onClick={handleAddService}
                >
                  Add Service
                </Button>
                <AddServiceModal />
                <Button
                  type="primary"
                  size={"small"}
                  style={{ marginLeft: "1em" }}
                  onClick={handleAddSubservice}
                >
                  Add Subservice
                </Button>
                <AddSubserviceModal />
              </Col>
            </Form.Item>
          </Col>
        </Row>

        {/* ---------------------------------------- */}
        <Form.Item
          style={{ padding: "20px" }}
          label="Connect as User"
          name="connectuserId"
          rules={[{ required: true, message: "User is required!" }]}
        >
          <Select
            style={{ width: 300, padding: "5px" }}
            placeholder="Select User"
            defaultValue={row.connectuserId}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >
            {Array.isArray(facebookDetails) &&
              facebookDetails?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.userNameWithCount}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <div>
          <label>Date Range</label>
          <Form.Item
            name="selectedDateRange"
            rules={[{ required: true, message: "Date Range is required!" }]}
            style={{ width: "52vw" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{ display: "flex", flexDirection: "row" }}
                onClick={() => {
                  setRangeUncheckedImg(1);
                  // setRunNowDisable(true);
                  form.setFieldsValue({
                    selectedDateRange: "1",
                    lastCount: lastCount.toString(),
                    // lastCountType: lastCountType,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg === 1 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                Last
                <Form.Item name="lastCount">
                  <Input
                    onChange={(e) => handleChangeLastCount(e, 1)}
                    type="number"
                    min={1}
                    style={{ width: 60, marginLeft: "2em", marginRight: "2em" }}
                  />
                </Form.Item>
                <Form.Item name="lastCountType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    style={{ width: 120 }}
                    defaultValue={
                      row.lastCountType == "string" ? "days" : row.lastCountType
                    }
                    onChange={handleChangeLastCountType}
                    options={[
                      { value: "days", label: "Days" },
                      { value: "weeks", label: "Weeks" },
                      { value: "months", label: "Months" },
                      { value: "years", label: "Years" },
                    ]}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setRangeUncheckedImg(2);
                  // setRunNowDisable(false);
                  form.setFieldsValue({
                    selectedDateRange: "2",
                    // fromDate: fromDate,
                    // toDate: toDate,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg === 2 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                From:
                <Form.Item name="fromDate">
                  <></>
                </Form.Item>
                <div>
                  <DatePicker
                    value={fromDate}
                    allowClear={false}
                    format={"MM/DD/YYYY"}
                    placeholder="MM/DD/YYYY"
                    onChange={handleChangeFromDate}
                    style={{ marginLeft: "2em", marginRight: "2em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current > moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                </div>
                <label>to: </label>
                <Form.Item name="toDate">
                  <></>
                </Form.Item>
                <div>
                  <DatePicker
                    value={toDate}
                    allowClear={false}
                    format={"MM/DD/YYYY"}
                    onChange={handleChangeToDate}
                    placeholder="MM/DD/YYYY"
                    style={{ marginLeft: "2em", marginRight: "2em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current > moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setRangeUncheckedImg(3);
                  // setRunNowDisable(false);
                  form.setFieldsValue({
                    selectedDateRange: "3",
                    // selectedInterval: selectedInterval.toString(),
                    // beginningCount: beginningCount.toString(),
                    // beginningType: beginningType1,
                    // beginningDate: beginning1Date,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg === 3 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                <Form.Item name="beginningCount">
                  <Input
                    type="number"
                    min={1}
                    // value={beginningCount}
                    style={{ width: 60, marginRight: "1em" }}
                    onChange={(e) => {
                      handleChangeBeginningCount(e, 3);
                    }}
                  />
                </Form.Item>
                <Form.Item name={"beginningType"}>
                  <Select
                    // defaultValue={row.beginningType == "string" ? 'days' : row.beginningType}
                    // value={row.beginningType == "string" ? 'days' : row.beginningType}
                    style={{ width: 120, marginRight: "1em" }}
                    onChange={handleBeginningTypeField}
                  >
                    {beginningTypeOptions.map((database, index) => (
                      <Option key={index} value={database.value}>
                        {database.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <div></div>
                Beginning on:
                <Form.Item name="beginningDate">
                  <DatePicker
                    allowClear={false}
                    // value={beginning1Date}
                    format={"MM/DD/YYYY"}
                    placeholder="MM/DD/YYYY"
                    // onChange={onChangeBeginningOn}
                    style={{ marginLeft: "1em", marginRight: "1em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current > moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                </Form.Item>
                <div></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setRangeUncheckedImg(4);
                  // setRunNowDisable(false);
                  form.setFieldsValue({
                    selectedDateRange: "4",
                    // endingCount: endingCount,
                    // endingType: endingType,
                    // endingDate: endingDate,
                  });
                }}
              >
                <img
                  src={rangeUncheckedImg == 4 ? radioChecked : radioUnchecked}
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                <Form.Item name="endingCount">
                  <Input
                    type="number"
                    // value={row.endingCount}
                    min={1}
                    style={{ width: 60, marginRight: "1em" }}
                    onChange={(e) => handleChangeEndingCount(e, 4)}
                  />
                </Form.Item>
                <Form.Item name="endingType">
                  <Select
                    style={{ width: 120, marginRight: "1em" }}
                    value={row.endingType}
                    onChange={(e) => {
                      // setEndingType(e);
                      form.setFieldsValue({
                        selectedDateRange: "4",
                        // endingCount: endingCount,
                        // endingType: e,
                        // endingDate: endingDate?.toString(),
                      });
                    }}
                    options={[
                      { value: "days", label: "Days" },
                      { value: "weeks", label: "Weeks" },
                      { value: "months", label: "Months" },
                      { value: "years", label: "Years" },
                    ]}
                  />
                </Form.Item>
                <div></div>
                Ending on:
                <Form.Item name="endingDate">
                  <DatePicker
                    allowClear={false}
                    // value={endingDate}
                    format={"MM/DD/YYYY"}
                    placeholder="MM/DD/YYYY"
                    // onChange={onChangeEndingOn}
                    style={{ marginLeft: "1em", marginRight: "1em" }}
                    disabledDate={(current) => {
                      let customDate = moment().format("YYYY-MM-DD");
                      return (
                        current && current > moment(customDate, "YYYY-MM-DD")
                      );
                    }}
                  />
                </Form.Item>
                <div></div>
              </div>
            </div>
          </Form.Item>
        </div>
        <div>
          <label>Connection Interval</label>
          <Form.Item
            style={{ width: "max-content" }}
            name="selectedInterval"
            rules={[
              { required: true, message: "Connection Interval is required!" },
            ]}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5px",
                }}
                onClick={() => {
                  setIntervalUncheckedImg(1);
                  setSelectedInterval(1);
                  form.setFieldsValue({
                    selectedInterval: "1",
                  });
                }}
              >
                <img
                  src={
                    intervalUncheckedImg === 1 ? radioChecked : radioUnchecked
                  }
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                Every
                <Form.Item name="everyTimeCount">
                  <Input
                    // onChange={(e) => handleChangeEveryTimeCount(e, 1)}
                    style={{ width: 60, marginLeft: "1em", marginRight: "1em" }}
                    type="number"
                    min={1}
                  />
                </Form.Item>
                <Form.Item name="everyTimeType">
                  <Select
                    // value={}
                    style={{ width: 120 }}
                    onChange={(e: string) => {
                      setEveryTimeType(e);
                      setSelectedInterval(1);
                      form.setFieldsValue({
                        selectedInterval: "1",
                        // everyTimeCount: everyTimeCount.toString(),
                        everyTimeType: e,
                      });
                    }}
                    options={[
                      { value: "seconds", label: "Seconds" },
                      { value: "minutes", label: "Minutes" },
                      { value: "hours", label: "Hours" },
                      { value: "days", label: "Days" },
                      { value: "weeks", label: "Weeks" },
                      { value: "months", label: "Months" },
                      { value: "years", label: "Years" },
                    ]}
                  />
                </Form.Item>
                <div></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setIntervalUncheckedImg(2);
                  setSelectedInterval(2);
                  form.setFieldsValue({
                    selectedInterval: "2",
                  });
                }}
              >
                <img
                  src={
                    intervalUncheckedImg === 2 ? radioChecked : radioUnchecked
                  }
                  style={{ height: "20px", width: "20px", marginRight: "1rem" }}
                />
                Every:
                <Form.Item name="everyWeek">
                  <Select
                    defaultValue="monday"
                    // value={everyWeek}
                    style={{
                      width: 120,
                      marginLeft: "1em",
                      marginRight: "1em",
                    }}
                    onChange={(e) => {
                      setEveryWeek(e);
                      setSelectedInterval(2);
                      form.setFieldsValue({
                        selectedInterval: "2",
                        everyWeek: e,
                      });
                    }}
                    options={[
                      { value: "sunday", label: "Sunday" },
                      { value: "monday", label: "Monday" },
                      { value: "tuesday", label: "Tuesday" },
                      { value: "wednesday", label: "Wednesday" },
                      { value: "thursday", label: "Thursday" },
                      { value: "friday", label: "Friday" },
                      { value: "saturday", label: "Saturday" },
                    ]}
                  />
                </Form.Item>
                <div></div>
                <label style={{ marginRight: "1em" }}>at: </label>
                <Form.Item name="everyWeekAtTime">
                  <TimePicker
                    style={{ marginRight: "1em", width: 80 }}
                    value={selectedTime}
                    format={format}
                    onChange={(value) => onChanget(value as Moment)}
                  />
                </Form.Item>
                <div></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: " 5px",
                }}
                onClick={() => {
                  setIntervalUncheckedImg(3);
                  setSelectedInterval(3);
                  form.setFieldsValue({
                    selectedInterval: "3",
                  });
                }}
              >
                <img
                  src={
                    intervalUncheckedImg === 3 ? radioChecked : radioUnchecked
                  }
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: ".5rem",
                  }}
                />
                Every
                <Form.Item name="everyDayCount">
                  <Input
                    type="number"
                    value={everyDayCount}
                    // onChange={(e) => handleChangeEveryDayCount(e, 3)}
                    style={{
                      marginLeft: ".5em",
                      marginRight: ".5em",
                      width: 60,
                    }}
                    min={1}
                    max={31}
                    onInput={(e) => {
                      let value = Number(e.currentTarget.value);
                      if (value == 1) {
                        setAbbreviation("st");
                      } else if (value == 2) {
                        setAbbreviation("nd");
                      } else if (value == 3) {
                        setAbbreviation("rd");
                      } else {
                        setAbbreviation("th");
                      }
                    }}
                  />
                </Form.Item>
                <label style={{ marginRight: ".5em", paddingTop: 5 }}>
                  {abbreviation}
                </label>
                <Form.Item name="everyDayDayType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    value={
                      row.everyDayDayType == "string"
                        ? "day"
                        : row.everyDayDayType
                    }
                    style={{ width: 120, marginRight: ".5em" }}
                    onChange={(e) => {
                      setEveryDayDayType(e);
                      setSelectedInterval(3);
                      form.setFieldsValue({
                        selectedInterval: "3",
                        everyDayDayType: e,
                      });
                    }}
                    options={[
                      { value: "day", label: "Day" },
                      { value: "week", label: "Week" },
                      { value: "month", label: "Month" },
                      { value: "quarter", label: "Quarter" },
                      { value: "half", label: "Half" },
                    ]}
                  />
                </div>
                of
                <Form.Item name="everyDayMonthOrYearType">
                  <></>
                </Form.Item>
                <div>
                  <Select
                    defaultValue={
                      row.everyDayMonthOrYearType !== "string"
                        ? row.everyDayMonthOrYearType
                        : "month"
                    }
                    style={{
                      width: 120,
                      marginRight: ".5em",
                      marginLeft: ".5em",
                    }}
                    onChange={(e) => {
                      setEveryMonthOrYearType(e);
                      setSelectedInterval(3);
                      form.setFieldsValue({
                        selectedInterval: "3",

                        everyDayMonthOrYearType: e,
                      });
                    }}
                    options={[
                      { value: "month", label: "month" },
                      { value: "year", label: "year" },
                    ]}
                  />
                </div>
              </div>
            </div>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Form.Item name="pageTitle">
            <Radio.Group
              onChange={onChangePagetitle}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Radio value={"VideoPosts"} style={{ marginTop: "1em" }}>
                Video Posts
              </Radio>
              <Radio value={"Posts"} style={{ marginTop: "1em" }}>
                Posts
              </Radio>
              <Radio checked value={"Page"} style={{ marginTop: "1em" }}>
                Page
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item
          label="Push results to sheet:"
          name="spreadsheetURL"
          rules={[
            {
              validator(_, value) {
                if (value == "") {
                  setValidStatus({ ...validStatus, sheetUrl: false });
                  return Promise.reject("Spreadsheet url field is required.");
                }
                var urlRegx = new RegExp("https://docs.google.com", "i");
                if (urlRegx.test(value)) {
                  setValidStatus({ ...validStatus, sheetUrl: true });
                  return Promise.resolve();
                } else {
                  setValidStatus({ ...validStatus, sheetUrl: false });
                  return Promise.reject(
                    "Please enter a valid spreadsheet url."
                  );
                }
              },
            },
          ]}
        >
          <Input value={spreadsheetURL} onChange={handleChangeSpreadsheetURL} />
        </Form.Item>
        <div style={{ textAlign: "center" }}>
          {" "}
          <Button target="_blank" href={spreadsheetURL || undefined} disabled={!spreadsheetURL}>
            Open Sheet
          </Button>
        </div>
        <div>
          <label>Replace or Append Data</label>
          <Form.Item name="appendOrReplace">
            <Radio.Group
              onChange={onChangeAppendType}
              // value={}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Radio checked value={"append"} style={{ marginTop: "1em" }}>
                Append Data
              </Radio>
              <Radio value={"replace"} style={{ marginTop: "1em" }}>
                Replace Data
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Row style={{ justifyContent: "space-around" }}>
          <Col
            span={11}
            style={{
              border: "groove",
              borderRadius: "25px",
              borderWidth: "thin",
              padding: "15px",
            }}
          >
            <Button
              loading={editLoading && !isRunNow}
              {...(editLoading && { disabled: true })}
              {...(!(
                (validStatus.name &&
                  validStatus.retailerID &&
                  validStatus.sheetUrl &&
                  intervalUncheckedImg === 1) ||
                intervalUncheckedImg === 2 ||
                intervalUncheckedImg === 3
              ) && { disabled: true })}
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure?",
                  content: "You want to Schedule Now?",
                  onOk: handleModalOk(false),
                });
              }}
              type="primary"
              danger
            >
              Schedule Now
            </Button>

            {/* <Form.Item> */}
            <Radio.Group name="connectionAndIntervalType"
              onChange={onChangeConnectionAndIntervalType}
              value={connectionAndIntervalType}
              style={{
                display: "flex",
                flexDirection: "column",
                width: 600,
                flexWrap: "wrap",
                alignContent: "center",
              }}
            >
              <Radio
                style={{ marginTop: "-2em", marginRight: "12em" }}
                value={"IstRunatInterval"}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  1st Run at Interval
                </span>
              </Radio>
              <Radio
                style={{ marginTop: "0.5em" }}
                value={"IstRunImmediatethenatInterval"}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  {" "}
                  1st Run Immediate, <br />{" "}
                  <span style={{ marginLeft: "2em" }}>then at Intervals</span>
                </span>
              </Radio>
            </Radio.Group>
            {/* </Form.Item> */}
          </Col>

          <Col
            span={11}
            style={{
              border: "groove",
              borderRadius: "25px",
              borderWidth: "thin",
              padding: "15px",
            }}
          >
            <Button
              loading={editLoading && isRunNow}
              {...(editLoading && !isRunNow && { disabled: true })}
              {...(!(
                validStatus.name &&
                validStatus.retailerID &&
                validStatus.sheetUrl &&
                validStatus.pageName
              ) && { disabled: true })}
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure?",
                  content: "You want to Run Now?",
                  onOk: handleModalOk(true),
                });
              }}
              type="primary"
            >
              Submit
            </Button>
            {/* <Form.Item > */}
            <Radio.Group
              name="connectionAndIntervalType"
              onChange={onChangeConnectionAndIntervalType}
              value={connectionAndIntervalType}
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignContent: "center",
              }}
            >
              <Radio
                style={{ marginTop: "-3em", marginLeft: "2em" }}
                value={"RunOnce"}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>Run Once</span>
              </Radio>
              <Radio
                style={{ marginTop: "0.5em", marginLeft: "2em" }}
                value={"SaveAsTemplate"}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  Save as Template
                </span>
              </Radio>
              <Radio
                style={{ marginTop: "0.5em", marginLeft: "2em" }}
                value={"UpdateOnly"}
              >
                <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  Update Only
                </span>
              </Radio>
            </Radio.Group>
            {/* </Form.Item> */}
          </Col>
        </Row>
        <Button onClick={handleCancel}>Cancel</Button>
      </div>
    </Modal>
  );
}